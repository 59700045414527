import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

function Logo() {
  return (
    <StaticImage
 
    src="https://cdn.shopify.com/s/files/1/0075/8995/3587/files/logo_medium.png?v=1556779408"
    alt="valuepetmeds" />
  )
}

export default Logo
