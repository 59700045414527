import { Link } from "gatsby"
import * as React from "react"
import { navStyle, navLink} from "./navigation.module.css"

export function Navigation({ className }) {


  return (
    <nav className={[navStyle, className].join(" ")}>
      <Link
        key="All"
        className={navLink}
        to="/products/"
      >
        All products
      </Link>

    </nav>
  )
}
